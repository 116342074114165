var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":"/"}},[_c('img',{staticClass:"fp_logo",attrs:{"src":_vm.logo,"alt":""}}),_c('div',[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"})])],1)]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{staticClass:"registerImage",attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-0 p-lg-2",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"px-xl-2 pt-5 pt-lg-0 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Connect With Us and Unlock New Possibilities ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Ready to Achieve Global Success? Sign In to Start Your Journey ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{attrs:{"label":"First name","label-for":"register-firstname"}},[_c('validation-provider',{attrs:{"name":"Firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","name":"register-firstname","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{attrs:{"label":"Last name","label-for":"register-lastname"}},[_c('validation-provider',{attrs:{"name":"Lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","name":"register-lastname","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{attrs:{"label":"Phone number","label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{directives:[{name:"validate",rawName:"v-validate",value:('min:10'),expression:"'min:10'"}],staticClass:"py-25",attrs:{"id":"register-mobile","name":"register-mobile","autoFormat":"","dropdownOptions":{
                        showSearchBox: true,
                        showFlags: true,
                        showDialCodeInSelection: false,
                      },"inputOptions":{
                    placeholder: 'Enter phone number',
                    required: true,
                    showDialCode: false
                  },"defaultCountry":"IN"},on:{"country-changed":_vm.countryChanged,"input":_vm.phoneNumber},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge rp_padding",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('b-form-group',{attrs:{"label-for":"register-password2","label":"Confirm Password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge rp_padding",attrs:{"id":"register-password2","type":_vm.passwordFieldTypeRepeat,"state":errors.length > 0 ? false : null,"name":"register-password2","placeholder":"············"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRepeat},on:{"click":_vm.togglePasswordVisibilityRepeat}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{},[_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.StudentRegister.apply(null, arguments)}}},[_vm._v(" Sign up ")])],1)])],1),(!_vm.is_agent_link)?_c('p',{staticClass:"text-center mt-2"},[_c('span',{staticClass:"text-gray"},[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" Sign in instead")])])],1):_vm._e(),(_vm.is_agent_link)?_c('div',{staticClass:"mt-4 text-center"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.agent_info.agent_name))]),_c('b-img',{attrs:{"src":_vm.FILESURL + _vm.agent_info.brand_image,"width":"200px"}})],1):_vm._e(),(_vm.is_sc_link)?_c('div',{staticClass:"mt-4 text-center"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.sc_info.sc_name))]),_c('b-img',{attrs:{"src":_vm.FILESURL + _vm.sc_info.sc_image,"width":"200px"}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }